nav {
  background-color: #7bafd4;
  color: white;
  padding: 20px 20px; /* Add horizontal padding */
  display: flex;
  justify-content: flex-start;
  overflow-x: auto; /* Add horizontal scroll if needed */
  overflow-y: auto; /* Add vertical scroll if needed */
  min-height: 100px;
}

.navList {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  align-items: center; /* Vertically center items */
  flex-wrap: nowrap;
}

.navItems {
  margin: 0 10px; /* Adjusted margin for better spacing */
  flex-grow: 1; /* Ensure items take up available space */
  flex-basis: auto; /* Allow items to take only the necessary space */
  text-align: center; /* Center the text */
}

.navItems.long-text {
  flex-grow: 2; /* Give more space to items with longer text */
  white-space: nowrap; /* Prevent text from breaking into multiple lines */
}

a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: color 0.3s;
  display: inline-block;
}

ul {
  list-style-type: none;
}

a:hover {
  text-decoration: none;
  color: #d47b61;
}

.navLogout {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  font: inherit;
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: color 0.3s;
}

.navLogout:hover {
  text-decoration: none;
  background: none;
  color: #d47b61; /* Change the color on hover to match the link color */
}

.active {
  text-decoration: underline;
}

.hamburger-icon {
  width: 2rem;
  height: 2rem;
  display: none;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
}

.bar {
  width: 2rem;
  height: 0.25rem;
  background-color: white;
}

/* CSS for Mobile Navigation List */
.mobile-navList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  right: -50%; /* Starts from the right and hidden */
  width: 50%; /* Takes up half of the screen width */
  height: 100vh; /* Takes up the full viewport height */
  background-color: #7bafd4;
  transition: right 0.3s linear; /* Slide-in/out transition */
  z-index: 1000; /* Ensures the nav list is above other elements */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 60px;
  overflow-y: auto;
}

.mobile-navList.open {
  right: 0; /* Fully visible when the .open class is added */
}

/* CSS for Navigation Items in Mobile Navigation List */
.mobile-navList .navItems {
  width: 100%;
  padding: 20px 0; /* Adds vertical padding */
  text-align: center; /* Centers the text */
  font-size: 1.5rem; /* Increases the font size */
  color: white; /* Text color */
}

/* CSS for Navigation Items on Hover in Mobile Navigation List */
.mobile-navList .navItems:hover {
  background-color: #d47b61; /* Darker background on hover */
}

.close-icon {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.bar.diagonal {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: white;
}

.part1 {
  transform: rotate(45deg);
}

.part2 {
  transform: rotate(-45deg);
}

/* Mobile View */
@media (max-width: 768px) {
  nav {
    justify-content: flex-end;
    padding: 1rem 1rem;
    overflow-y: auto;
  }
  .hamburger-icon {
    display: flex; /* Show hamburger icon in mobile view */
  }

  .navList {
    display: none; /* Hide desktop nav in mobile view */
  }

  .navItems {
    margin: 0;
  }
}

/* Add this to your navigation.css */
.navScroll {
  overflow-y: auto;
  padding-right: 15px; /* Add padding for better spacing */
}
