.container {
  width: 65%;
  margin: auto;
}

.accordion-item {
  margin: 5px;

  margin-left: 0;
  border: 1px solid #ccc;
}

.accordion-title {
  background: #f7f7f7;
  border: none;
  cursor: pointer;
  padding: 1em;

  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-title.active {
  background: #e7e7e7;
}

.accordion-content {
  padding: 5px;
  background: white;
  display: none;
}

.accordion-content-item {
  margin: 0;
  padding: 0.5em 0;
}

.accordion-title[aria-expanded="true"] + .accordion-content {
  display: block;
}

.arrow {
  font-size: 1.5em;
  line-height: 0;
}

.documentsInformationContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  width: 100%;
}

.websiteLink {
  color: #007bff;
  font-weight: normal;
  padding-right: 8px;
}

.documentsInformationTable {
  width: 100%;
}

@media (max-width: 768px) {
  .fclCoLoaderTable {
    overflow-x: auto;
  }

  h2 {
    text-align: center;
  }
}

.extraShippingInformationTable {
  width: 80%;
  overflow-y: auto;

  height: auto;
  max-height: 400px;
}
.extraShippingInformationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  padding: 20px;
}
