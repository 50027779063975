/* SearchForm.css */

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.search-content {
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 20px;
}

.search-form-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space elements evenly */
  gap: 10px; /* Adjust the spacing between input elements */
  margin-top: 20px;
}

.formInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white; /* Remove default background */
  border: 1px solid #ccc; /* Add your custom border */
  border-radius: 3px; /* Add your custom border radius */
  padding: 10px; /* Add padding as desired */
  font-size: 100%;

  width: 100%;
}

.searchButton {
  background-color: #7bafd4;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.searchButton:hover {
  background-color: #d47b61;
}

.searchButton:focus {
  outline: none; /* Remove the default outline */
  background-color: #ffa07a; /* Change background color to highlight */
  box-shadow: 0 0 5px #ffa07a; /* Add a box shadow for better visibility */
}

.search-results-container {
  margin-top: 20px; /* Add some top margin to separate it from the search form */
  width: 80%; /* Set the width to 50% of the parent container */
  margin: 0 auto; /* Center the container horizontally */
}

/* Style for the profit table with brighter colors */
.profitTable {
  background-color: #fff; /* A slightly brighter background color */
  border: 1px solid #ccc; /* A slightly brighter border color */
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.profitTable th {
  background-color: #7bafd4; /* A slightly brighter header background color */
  color: #fff;
}

.profitTable td {
  padding: 10px;
}

.profitTable tr {
  border-bottom: 1px solid #ddd; /* A slightly brighter border color for rows */
}

.profitInput {
  width: 5rem;
}

.footer-line-break {
  white-space: pre-line; /* Enables natural line breaks within the cell */
}

.scroll-table {
  overflow-x: auto;
}
/* Style for the table */
table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

tfoot td {
  font-weight: bold;
  background-color: #f2f2f2;
  color: #333;
  text-align: left;
}

.table-footer {
  font-size: 13px;
}

th {
  background-color: #919da5;
  color: #fff;
  padding: 10px;
  text-align: left;
}

.charges__header {
  background-color: #ff5733;
}

/* Style for table rows */
tr {
  border-bottom: 1px solid #ccc;
}

/* Style for table cells */
td {
  padding: 10px;

  width: 5%;
}

/* In your CSS stylesheet */
.react-autosuggest__input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
}

.react-autosuggest__container {
  position: relative; /* Ensure suggestions appear relative to the container */

  width: 100%;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  top: 100%; /* Position suggestions below the input */
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  max-height: 200px; /* Set a maximum height for the suggestions */
  overflow-y: auto; /* Add a scrollbar if needed */
  z-index: 999; /* Ensure suggestions are on top of other content */
}

.react-autosuggest__suggestion {
  padding: 8px 16px;
  display: block;
  cursor: pointer;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #7bafd4; /* Highlighted suggestion style */
  color: white;
}

.profit-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.profit-button:hover {
  background-color: none;
}

.search-logo {
  width: 100%;
  height: auto;
}

.search-images {
  width: 100%;
  display: flex;
  justify-content: center;
}

.compareRatesTable tbody tr td:first-child {
  font-weight: bold; /* Make text in the first cell of each row bold */
}

.compareRatesTable th,
.compareRatesTable td {
  border: 1px solid black; /* Add a solid black border to headers and cells */
}

@media (max-width: 768px) {
  .search-logo {
    max-width: 50%; /* Allow the form to take the full width on small screens */
  }

  .search-form-container {
    flex-direction: column; /* Stack input fields vertically on small screens */
  }

  .formInput {
    width: 100%; /* Full width for input fields on small screens */
  }

  button {
    width: 100%; /* Full width for the button on small screens */
  }

  .react-autosuggest__input {
    width: 100%;
  }
  .search-results-main {
    overflow-x: auto;
  }
  .search-results-container table {
    font-size: 14px; /* Reduce font size for smaller screens */
  }
}

/* Media Query for Tablets (768px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .search-logo {
    max-width: 35%; /* Allow the form to take the full width on small screens */
  }

  .search-form-container {
    flex-direction: column; /* Stack input fields vertically on small screens */
    width: 75%;
  }

  .formInput {
    width: 100%; /* Full width for input fields on small screens */
  }

  button {
    width: 100%; /* Full width for the button on small screens */
  }

  .react-autosuggest__input {
    width: 100%;
  }

  .search-results-main {
    overflow-x: auto;
  }
  .search-results-container table {
    font-size: 14px; /* Reduce font size for smaller screens */
  }
}

/* Media Query for Small Screens (1025px - 1200px) */

@media (min-width: 1025px) and (max-width: 1200px) {
  .search-logo {
    max-width: 25%; /* Allow the form to take the full width on small screens */
  }

  .search-form-container {
    width: 100%;
  }

  .search-results-container {
    width: 100%;
  }
}

/* Media Query for Large Screens (1201px and above) */
@media (min-width: 1201px) {
  .search-logo {
    max-width: 25%; /* Allow the form to take the full width on small screens */
  }
}
