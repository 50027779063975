/* login.css */

/* Default size for the logo */
.companyLogo {
  width: 100%; /* Make the image fill its container's width */
  height: auto; /* Maintain the image's aspect ratio */
}


.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px; /* Add some padding for better spacing on small screens */
}

.loginInput {
  flex: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 12px;
  font-size: 16px;
  width: 100%;
}

.loginForm {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff; /* Match the background color of other parts of your website */
  color: #333; /* Text color */
  gap: 20px;
}






.loginButton {
  background-color: #7BAFD4; /* Match your website's primary color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.loginButton:hover {
  background-color: #D47B61; /* Match your website's secondary color */
}

/* Media Query for Small Screens (e.g., smartphones) */
@media (max-width: 768px) {
  .loginForm {
    max-width: 100%; /* Allow the form to take the full width on small screens */
    padding: 10px; /* Reduce padding for small screens */
  }

  .loginInput {
    font-size: 14px; /* Reduce font size for small screens */
  }

  button {
    padding: 12px 30px; /* Increase button padding for small screens */
  }

  /* Reduce the width of the logo for small screens */
  .companyLogo {
    max-width: 80%; /* Adjust the width as needed */
  }
}

/* Media Query for Tablets (768px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .loginForm {
    /* You can add specific styles for tablet-sized screens here */
    max-width: 35%;
  }

  .companyLogo {
    max-width: 35%;
  }

  /* Adjust other elements as needed */
}

/* Media Query for Small Screens (1025px - 1200px) */



@media (min-width: 1025px) and (max-width: 1200px) {
  .loginForm {
    /* You can add specific styles for tablet-sized screens here */
    max-width: 30%;
  }

  .companyLogo {
    max-width: 30%;
  }
}

/* Media Query for Large Screens (1201px and above) */
@media (min-width: 1201px) {
  .loginForm {
    /* You can add specific styles for tablet-sized screens here */
    max-width: 20%;
  }

  .companyLogo {
    max-width: 20%;
  }
}
