.carrierContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.carrierTable {
  width: 60%;
  max-height: 400px;
  height: auto;
  overflow-y: auto;
}

.carrierContact {
  color: #007bff;
  font-weight: normal;
  padding-right: 8px;
}

.tableButton {
  display: inline-block;
  padding: 10px 15px;
  background-color: #7bafd4;
  color: white;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.tableButton:hover {
  text-decoration: none;

  background-color: #d47b61;
}
.carrierTitle {
  text-align: center;
}



@media (max-width: 768px) {
  .hazVehicleTable {
    overflow-x: auto;
  }
  h2 {
    text-align: center;
  }

  .carrierTable {
    max-height: 300px;
  }
}
