.informationContainer {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.websiteLink {
  color: #007bff;
  font-weight: normal;
  padding-right: 8px;
}

.informationTable {
  width: 60%;
}
@media (max-width: 768px) {
  .fclCoLoaderTable {
    overflow-x: auto;
  }
  h2 {
    text-align: center;
  }
}
.extraInformationTable {
  width: 80%;
  overflow-y: auto;

  height: auto;
  max-height: 400px;
}

.extraInformationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  padding: 20px;
}
.extraShippingInformationTable {
  width: 80%;
  overflow-y: auto;

  height: auto;
  max-height: 400px;
}

.extraWiderInformationTable {
  width: 90%;
  overflow-y: auto;

  height: auto;
  max-height: 400px;
}
.extraShippingInformationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  padding: 20px;
}
.wideColumn {
  min-width: 200px;
}
.widerColumn {
  min-width: 280px;
}
