footer {
    background-color: #7BAFD4;
    color: white;
    width: 100%;
    padding: 20px;
}

footer p {
    margin: 0;
}

.footer-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}













.footer-logo {
    width: 100%;
    height: auto;
}


@media (max-width: 768px) {
    footer {
        padding: 5px;
    }
    .footer-logo {
        max-width: 20%;
    }
  }

  /* Media Query for Tablets (768px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    footer {
        padding: 5px;
    }
    .footer-logo {
        max-width: 5%;
    }
  }
  
  /* Media Query for Small Screens (1025px - 1200px) */
  
  
  @media (min-width: 1025px) and (max-width: 1200px) {
    footer {
        padding: 5px;
    }
    .footer-logo {
        max-width: 5%;
    }
  }
  
  /* Media Query for Large Screens (1201px and above) */
  @media (min-width: 1201px) {
    footer {
        padding: 20px;
    }
    .footer-logo {
        max-width: 5%;
    }
  }