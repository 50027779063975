.file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.file-upload-form-container {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 300px; /* Width adjusted for a square-like form */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-upload-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-input {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px; /* Spacing between the input and the button */
}

.upload-button {
  background-color: #7bafd4;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Make the button full width */
}

.tutorial-button {
  background-color: #8abe9b;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Make the button full width */
}

.upload-button:hover {
  background-color: #d47b61;
}

/* Styles for the title text */
.file-upload-text {
  color: #333;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.button-style {
  background-color: #8abe9b; /* Green background */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block; /* To keep it inline but also allow width and padding */
  width: 100%; /* Make the button full width */
}

.file-upload-form-container * {
  margin-bottom: 10px; /* Adds space below each element */
}

.file-upload-form-container *:last-child {
  margin-bottom: 0; /* Removes margin from the last element */
}
.button-style,
.upload-button {
  margin-top: 10px; /* Adds space above the buttons */
}
