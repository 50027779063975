.companyHomeCar {
  width: 25%;
  height: auto;
}

.companyHomeLogo {
  width: 20%;
  height: auto;
}

.homeContent {
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.rateButton {
  display: flex;
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  padding: 10px 15px;
  background-color: #7bafd4;
  color: white;
  text-decoration: none; /* Remove default underlines */
  border: none;
  border-radius: 3px;
  cursor: pointer;
  height: 40px; /* Fixed height for consistency */
  box-sizing: border-box; /* Ensure padding is included in height */
}

.rateButton:hover {
  background-color: #d47b61;
}

.buttonContent {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: 75%;
  justify-content: center;
}

@media (max-width: 768px) {
  .companyHomeCar {
    width: 50%;
  }

  .companyHomeLogo {
    width: 50%;
  }

  .rateButton {
    text-align: center;
    height: auto; /* Adjust height for mobile */
    padding: 10px; /* Adjust padding for mobile */
  }

  .buttonContent {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%; /* Use full width on mobile */
    justify-content: flex-start; /* Align items to the start */
  }
}

.rateButton:hover {
  background-color: #d47b61;
}

@media (max-width: 768px) {
  .rateButton {
    width: 100%; /* Full width on mobile */
    height: auto; /* Adjust height for mobile */
    padding: 10px; /* Adjust padding for mobile */
  }

  .buttonContent {
    flex-direction: column; /* Stack buttons vertically on mobile */
    align-items: center; /* Center buttons horizontally */
    overflow-y: auto;
    height: 300px;
  }
}
