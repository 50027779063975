.carrierContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hazInstructions {
  color: #007bff;
  font-weight: normal;
  padding-right: 8px;
}

.hazVehicleTable {
  width: 75%;
}

@media (max-width: 768px) {
  .hazVehicleTable {
    overflow-x: auto;
  }

  h2 {
    text-align: center;
  }
}
